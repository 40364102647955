import React from 'react'
import ReviewContent from './review_content'
import Footer from '../../landingpages/footer/footer'

export default function Review() {
  return (
    <>
        <ReviewContent />
        <Footer />
    </>
  )
}
