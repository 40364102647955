// import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import Home from "./components/landingpages/homepage/home";
// import Cart from './components/productpages/cart/cart';
// import ProductDetails from './components/productpages/product_details/product_details';
// import ProductList from './components/productpages/product_list/product_list';
// import Login from './components/login/login'
// import Registration from './components/registration/registration';
// import SubCategory from './components/sub_category/sub_category';
// import ConfirmOrder from './components/productpages/confirm_order/confirm_order';
// import EmptyCart from './components/productpages/cart/empty_cart';
// import OrderFailed from './components/productpages/confirm_order/order_failed';
// import OrderSuccess from './components/productpages/confirm_order/order_success';
// import PhoneVerification from './components/login/phone_verification';
// import ResetPass from './components/login/reset_pass';
// import AccountCreated from './components/registration/account_created';
// import MobileLogin from './components/login/mobile_login';

import ManagingRoutes from "./components/routes/managing_routes";

function App() {
  return (
      <>
          <ManagingRoutes />
      </>
  );
}

export default App;
