import React from 'react'

export default function ProductListContent() {
  return (
    <>
        <div id='page'>
            {/* Top fixed header */}
            <div className="page-subtitle mb-0 mt-2 border-bottom">
                <a href="#" className="page-subtitle-icon" data-back-button><i className="fa fa-arrow-left color-theme" /></a>
                <h1 className="font-18 font-500">Invitations latest Designs</h1>
                <a href="#" className="page-subtitle-icon" data-menu="menu-main"><i className="fa fa-bars color-theme" /></a>
            </div> 
            {/* Top fixed header */}
            <div className='page-content'>
                {/* filter and options start */}
                <div className="filter-options">
                    <div className="row mb-0 p-2 tab-controls">
                    <a href="#" className="col-6" data-tab="tab-filter">
                        <div className="text-center d-flex justify-content-center">
                        <img className="filter-img" src="assets/images/ark/filter.png" width="18px" height="18px" alt="" />
                        <h5 className="font-16 font-500 ml-1">Filter</h5>
                        </div>
                    </a>
                    <a href="#" className="col-6" data-tab="tab-option">
                        <div className="text-center d-flex justify-content-center">
                        <img className="filter-img" src="assets/images/ark/options.png" width="18px" height="18px" alt="" />
                        <h5 className="font-16 font-500 ml-1">Options</h5>
                        </div>
                    </a>
                    </div>
                    <div className="tab-content custom-tab-content p-2" id="tab-filter">
                    <h4 className="font-500 font-14">FILTER MEDIA</h4>
                    <div className="divider divider-margins mb-2 ml-0 mr-0" />
                    <div className="row mb-1">
                        <div className="col-12">
                        <h5 className="font-600">Latest Media Designs</h5>
                        <div className="divider divider-margins mb-2 ml-0 mr-0" />
                        <h6 className="font-300">Popularity</h6>
                        <div className="divider divider-margins mb-2 ml-0 mr-0" />
                        <h6 className="font-300">Price - High to low</h6>
                        <div className="divider divider-margins mb-2 ml-0 mr-0" />
                        <h6 className="font-300">Price - Low to High</h6>
                        <div className="divider divider-margins mb-2 ml-0 mr-0" />
                        <h6 className="font-300">Rating</h6>
                        </div>
                    </div>
                    </div>
                    <div className="tab-content custom-tab-content p-2" id="tab-option">
                    <h4 className="font-500 font-14">FILTER WEDDING MEDIA OPTIONS</h4>
                    <div className="divider divider-margins mb-2 ml-0 mr-0" />
                    <h6 className="font-500 font-14">Options Variations</h6>
                    <div className="d-flex justify-content-around">
                        <a href="#" type="checkbox" className="m-1 btn btn-border btn-xs btn-full mb-3 rounded-xl text-uppercase font-500 border-info text-info bg-theme">Standard</a>
                        <a href="#" className="m-1 btn btn-border btn-xs btn-full mb-3 rounded-xl text-uppercase font-500 border-info text-info bg-theme">Modern</a>
                        <a href="#" className="m-1 btn btn-border btn-xs btn-full mb-3 rounded-xl text-uppercase font-500 border-info text-info bg-theme">Muslims</a>
                        <a href="#" className="m-1 btn btn-border btn-xs btn-full mb-3 rounded-xl text-uppercase font-500 border-info text-info bg-theme">Tradition</a>
                    </div>
                    <div className="divider divider-margins mb-2" />
                    <h6 className="font-500 font-14">Tag Options</h6>
                    <div className="d-flex justify-content-around">
                        <a href="#" className="m-1 btn btn-border btn-xs btn-full mb-3 rounded-xl text-uppercase font-500 border-info text-info bg-theme">Hindus</a>
                        <a href="#" className="m-1 btn btn-border btn-xs btn-full mb-3 rounded-xl text-uppercase font-500 border-info text-info bg-theme">Christian</a>
                        <a href="#" className="m-1 btn btn-border btn-xs btn-full mb-3 rounded-xl text-uppercase font-500 border-info text-info bg-theme">Muslims</a>
                        <a href="#" className="m-1 btn btn-border btn-xs btn-full mb-3 rounded-xl text-uppercase font-500 border-info text-info bg-theme">Universal</a>
                    </div>
                    <div className="divider divider-margins mb-2" />
                    <h6 className="font-500 font-14 mb-2">Discount Options</h6>
                    <div className="d-flex justify-content-around">
                        <a href="#" className="btn btn-border btn-xs btn-full mb-3 rounded-xl text-uppercase font-500 border-info text-info bg-theme" style={{padding: '5px 25px !important'}}>0%</a>
                        <a href="#" className="btn btn-border btn-xs btn-full mb-3 rounded-xl text-uppercase font-500 border-info text-info bg-theme" style={{padding: '5px 25px !important'}}>20%</a>
                        <a href="#" className="btn btn-border btn-xs btn-full mb-3 rounded-xl text-uppercase font-500 border-info text-info bg-theme" style={{padding: '5px 25px !important'}}>50%</a>
                        <a href="#" className="btn btn-border btn-xs btn-full mb-3 rounded-xl text-uppercase font-500 border-info text-info bg-theme" style={{padding: '5px 25px !important'}}>80%</a>
                    </div>
                    <div className="divider divider-margins mb-2" />
                    <div className="d-flex justify-content-around">
                        <a href="#" className="btn btn-xs btn-full mb-3 rounded-xs text-uppercase font-900 shadow-s bg-teal-light">Clear</a>
                        <a href="#" className="btn btn-xs btn-full mb-3 rounded-xs text-uppercase font-900 shadow-s bg-blue-dark">View all</a>
                    </div>
                    </div>
                </div>
                {/* filter and options end */}

                <div class="divider divider-margins mb-0 ml-0 mr-0"></div>

                {/* Product List Start */}
                <div className='content mt-2 mb-1'>
                    <div className="row mb-0">
                        <div className="d-flex mb-2">
                            <div className="col-7 pl-1">
                                <video width="100%" poster="assets/images/ark/digital.png" controls loop muted>
                                <source src="assets/images/ark/saree.mp4" type="video/mp4" />
                                <source src="mov_bbb.ogg" type="video/ogg" />
                                Your browser does not support HTML video.
                                </video>
                            </div>
                            <div className="col-5 p-0">
                                    <a href="product_details">
                                    <h5 className="font-500 font-14">Wedding Design name</h5>
                                    <p className="mt-n2 mb-1 font-11">WED00000001</p>
                                    </a>
                                    <button className="border-info btn btn-border btn-xxs text-info font-600 mb-1 rounded-xl text-sentance">Modren</button>
                                    <button className="border-info btn btn-border btn-xxs text-info font-600 mb-1 rounded-xl text-sentance">Hindus</button>
                                    <del className="font-11" style={{color: '#f37795'}}>$50.00</del>&nbsp;<span className="font-500 font-14">$37.88</span>&nbsp;<span className="font-11" style={{color: 'aquamarine !important'}}>-60%OFF</span>
                                <div>
                                    <span>
                                        <i className="fa fa-star color-yellow-dark" />
                                        <i className="fa fa-star color-yellow-dark" />
                                        <i className="fa fa-star color-yellow-dark" />
                                        <i className="fa fa-star color-yellow-dark" />
                                        <i className="fa fa-star-half-alt color-yellow-dark" />
                                    </span>
                                    <span className="fa fa-share-alt pl-2" />
                                    <span className="fa fa-heart pl-2" />
                                </div>                                      
                            </div>
                        </div>
                        {/* <div className="d-flex mb-2">
                            <div className="col-7 pl-1">
                                <video width="100%" poster="assets/images/ark/digital.png" controls loop muted>
                                <source src="assets/images/ark/saree.mp4" type="video/mp4" />
                                <source src="mov_bbb.ogg" type="video/ogg" />
                                Your browser does not support HTML video.
                                </video>
                            </div>
                            <div className="col-5 p-0">
                                <a href="product_details">
                                    <h5 className="font-500 font-14">Wedding Design name</h5>
                                    <p className="mt-n2 mb-1 font-11">WED00000001</p>
                                </a>
                                <button className="border-info btn btn-border btn-xxs text-info font-600 mb-1 rounded-xl text-sentance">Modren</button>
                                <button className="border-info btn btn-border btn-xxs text-info font-600 mb-1 rounded-xl text-sentance">Hindus</button>
                                <del className="font-11" style={{color: '#f37795'}}>$50.00</del>&nbsp;<span className="font-500 font-14">$37.88</span>&nbsp;<span className="font-11" style={{color: 'aquamarine !important'}}>-60%OFF</span>
                                <div>
                                <span>
                                    <i className="fa fa-star color-yellow-dark" />
                                    <i className="fa fa-star color-yellow-dark" />
                                    <i className="fa fa-star color-yellow-dark" />
                                    <i className="fa fa-star color-yellow-dark" />
                                    <i className="fa fa-star-half-alt color-yellow-dark" />
                                </span>
                                <span className="fa fa-share-alt pl-2" />
                                <span className="fa fa-heart pl-2" />
                                </div>                                      
                            </div>
                        </div>
                        <div className="d-flex mb-2">
                            <div className="col-7 pl-1">
                                <video width="100%" poster="assets/images/ark/digital.png" controls loop muted>
                                <source src="assets/images/ark/saree.mp4" type="video/mp4" />
                                <source src="mov_bbb.ogg" type="video/ogg" />
                                Your browser does not support HTML video.
                                </video>
                            </div>
                            <div className="col-5 p-0">
                                <a href="product_details">
                                    <h5 className="font-500 font-14">Wedding Design name</h5>
                                    <p className="mt-n2 mb-1 font-11">WED00000001</p>
                                </a>
                                <button className="border-info btn btn-border btn-xxs text-info font-600 mb-1 rounded-xl text-sentance">Modren</button>
                                <button className="border-info btn btn-border btn-xxs text-info font-600 mb-1 rounded-xl text-sentance">Hindus</button>
                                <del className="font-11" style={{color: '#f37795'}}>$50.00</del>&nbsp;<span className="font-500 font-14">$37.88</span>&nbsp;<span className="font-11" style={{color: 'aquamarine !important'}}>-60%OFF</span>
                                <div>
                                <span>
                                    <i className="fa fa-star color-yellow-dark" />
                                    <i className="fa fa-star color-yellow-dark" />
                                    <i className="fa fa-star color-yellow-dark" />
                                    <i className="fa fa-star color-yellow-dark" />
                                    <i className="fa fa-star-half-alt color-yellow-dark" />
                                </span>
                                <span className="fa fa-share-alt pl-2" />
                                <span className="fa fa-heart pl-2" />
                                </div>                                      
                            </div>
                        </div>
                        <div className="d-flex mb-2">
                            <div className="col-7 pl-1">
                                <video width="100%" poster="assets/images/ark/digital.png" controls loop muted>
                                <source src="assets/images/ark/saree.mp4" type="video/mp4" />
                                <source src="mov_bbb.ogg" type="video/ogg" />
                                Your browser does not support HTML video.
                                </video>
                            </div>
                            <div className="col-5 p-0">
                                <a href="product_details">
                                    <h5 className="font-500 font-14">Wedding Design name</h5>
                                    <p className="mt-n2 mb-1 font-11">WED00000001</p>
                                </a>
                                <button className="border-info btn btn-border btn-xxs text-info font-600 mb-1 rounded-xl text-sentance">Modren</button>
                                <button className="border-info btn btn-border btn-xxs text-info font-600 mb-1 rounded-xl text-sentance">Hindus</button>
                                <del className="font-11" style={{color: '#f37795'}}>$50.00</del>&nbsp;<span className="font-500 font-14">$37.88</span>&nbsp;<span className="font-11" style={{color: 'aquamarine !important'}}>-60%OFF</span>
                                <div>
                                <span>
                                    <i className="fa fa-star color-yellow-dark" />
                                    <i className="fa fa-star color-yellow-dark" />
                                    <i className="fa fa-star color-yellow-dark" />
                                    <i className="fa fa-star color-yellow-dark" />
                                    <i className="fa fa-star-half-alt color-yellow-dark" />
                                </span>
                                <span className="fa fa-share-alt pl-2" />
                                <span className="fa fa-heart pl-2" />
                                </div>                                      
                            </div>
                        </div> */}
                    </div>
                </div>
                {/* Product List End */}

            </div>
        </div>
    </>
  )
}
