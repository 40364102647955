import React from 'react'

export default function MobileLogin() {
  return (
    <>
        <div id='page'>
            <div className="page-content pb-0 mb-0">
                <div className="card" data-card-height="cover">
                    <div className="card-center px-4">
                        <img src="assets/images/ark/logo-bg.png" alt="" width="350" className="d-block m-auto" />
                        <div className="input-style input-style-2 input-required">
                        <input className="form-control border border-secondary" type="tel" placeholder="Phone" />
                        </div>
                        <a href="phone_verification" className="btn btn-full btn-xs bg-dark rounded-sm mt-3 mb-2 font-400 font-15 shadow-xl">Sign In</a>
                        <span className="text-secondary font-100 font-12">OR login with
                            <a href='login'><span className="font-800 font-14 custom-forget">&nbsp;&nbsp;UserName & Password</span></a>
                        </span>
                        <div className="text-center mt-3 mb-3 d-flex justify-content-between">
                        <span className="text-secondary font-100 font-11">Didn't have an account ?
                            <a href='registration'><span className="font-800 font-14 custom-forget">&nbsp;&nbsp;Sign Up</span></a>
                        </span>
                        <a href className="text-white font-600">Forgot Password?</a><br />
                        </div>
                        <div className="text-center"> 
                        <a href="#" className="mr-3"><img src="assets/images/ark/facebook.png" width={50} alt="" /></a>
                        <a href="#"><img src="assets/images/ark/google-plus.png" width={50} alt="" /></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>

  )
}
