import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import AccordionDetails from '@mui/material/AccordionDetails';
import VisibilityIcon from '@mui/icons-material/Visibility';

export default function ProductDetailsContent() {
  return (
    <>
        <div id='page'>
            {/* Top fixed header */}
            <div className="page-subtitle mb-1 mt-2 border-bottom">
                <a href="#" className="page-subtitle-icon" data-back-button><i className="fa fa-arrow-left color-theme" /></a>
                <h1 className="font-18 font-500">Product Details</h1>
                <a href="#" className="page-subtitle-icon" data-menu="menu-main"><i className="fa fa-bars color-theme" /></a>
            </div> 
            {/* Top fixed header */}

            {/* Top header it appearers when Scroll down the page */}
            <div className="header header-fixed header-logo-app header-auto-show">
                <a href="index.html" className="header-title font-14">Product Details</a>
                <a href="#" data-back-button className="header-icon header-icon-1"><i className="fas fa-arrow-left" /></a>
                <a href="#" data-menu="menu-main" className="header-icon header-icon-2"><i className="fas fa-bars" /></a>
            </div>
            {/* Top header it appearers when Scroll down the page */}

            <div className='page-content'>
                {/* Product */}
                <div className="card" data-card-height={225}>
                    <div className="card-top">
                    <video width="100%" poster="assets/images/ark/digital.png" controls loop muted>
                        <source src="assets/images/ark/saree.mp4" type="video/mp4" />
                        <source src="mov_bbb.ogg" type="video/ogg" />
                        Your browser does not support HTML video.
                    </video> 
                    </div>            
                </div>
                {/* Product */}
                
                {/* Product Details */}
                <div className="content mb-0">
                    <h4 className="font-500 font-18 mb-0">Product Information</h4>
                    <p className="pb-0 mb-0">WDI0000000001</p>
                    <button className="border-blue-dark btn btn-border btn-xxs color-blue-dark font-600 mb-1 rounded-xl text-sentance">Modern</button>
                    <button className="border-blue-dark btn btn-border btn-xxs color-blue-dark font-600 mb-1 rounded-xl text-sentance">Hindus</button>
                    <br />
                    <span>
                    <del className="font-11" style={{color: '#f37795'}}>$50.00</del>&nbsp;<span className="font-500 font-14">$37.88</span>&nbsp;<span className="font-11" style={{color: 'aquamarine !important'}}>-60%OFF</span>
                    </span>
                    <p className="pb-0 mb-0 font-11">MRP Basic Media Product</p>
                    <p className="pb-0 mb-0 color-green-light font-600">Including all taxes</p>
                    <div className="custom-rating">
                    <span><i className="fa fa-star color-yellow-dark" /></span>
                    <span><i className="fa fa-star color-yellow-dark" /></span>
                    <span><i className="fa fa-star color-yellow-dark" /></span>
                    <span><i className="fa fa-star" /></span>
                    <span><i className="fa fa-star" /></span>
                    <span className="text-white ml-2">(23)</span>
                    </div>
                </div>
                {/* Product Details */}

                <div className="divider-white mb-0 ml-2 mr-2" />

                {/* Product Description */}
                <div className='content mb-1 mt-1  '>
                    <Accordion className='theme-dark'>
                        <AccordionSummary className="p-0"
                        expandIcon={<ExpandMoreIcon className='color-white' />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        >
                        <VisibilityIcon className='color-white font-16 mt-1 mr-3' />
                        {/* <i className="fa font-14 fa-eye color-white" /> */}
                        <Typography>Product Details</Typography>
                        </AccordionSummary>
                        <AccordionDetails className='pl-0 pr-0'>
                        {/* Media Specifications */}
                        <div className="list-group list-custom-small mt-1">
                            <h5 className="font-600 font-15 mb-2">Media Specifications</h5>
                            <div className="row mb-0">
                            <div className="col-6 right-border"> 
                                <p className="pb-0 mb-0 text-white line-height-m">Product Duration</p>    
                                <p className="pb-0 mb-0 line-height">Trailor</p>      
                            </div> 
                            <div className="col-6 left-border">
                                <p className="pb-0 mb-0 text-white line-height-m">Product Varaitains Models</p>
                                <p className="pb-0 mb-0 line-height"> Modern</p>
                            </div>    
                            </div>
                        </div>
                        <div className="divider-white mt-2 mb-2 mr-0 ml-0" style={{}} />
                        {/* Media Specifications */}
                        {/*  */}
                        <div className="list-group list-custom-small">                    
                            <div className="row mb-0">
                            <div className="col-6 right-border"> 
                                <p className="pb-0 mb-0 text-white line-height-m">Product Languages</p>    
                                <p className="pb-0 mb-0 line-height">English, Hindi, Telugu, Urdu</p>      
                            </div> 
                            <div className="col-6 left-border">
                                <p className="pb-0 mb-0 text-white line-height-m">Product Sound</p>
                                <p className="pb-0 mb-0 line-height">No Sound</p>
                            </div>    
                            </div>
                        </div>
                        <div className="divider-white mt-2 mb-2 mr-0 ml-0" style={{}} />
                        {/*  */}
                        {/* Media Information */}
                        <div className="list-group list-custom-small">
                            <h6 className="font-600 font-15 mb-2">Media Information</h6>                 
                            <div className="row mb-0">
                            <div className="col-6 right-border"> 
                                <p className="pb-0 mb-0 text-white line-height-m">Format Type</p>    
                                <p className="pb-0 mb-0 line-height">Digital Format [ video ]</p>      
                            </div> 
                            <div className="col-6 left-border">
                                <p className="pb-0 mb-0 text-white line-height-m">Format Size</p>
                                <p className="pb-0 mb-0 line-height">HD / FULL HD / 4K [ on selected based ]</p>
                            </div>    
                            </div>
                        </div>
                        <div className="divider-white mt-2 mb-2 mr-0 ml-0" style={{}} />
                        {/* Media Information */}
                        {/*  */}
                        <div className="list-group list-custom-small">
                            <div className="row mb-0">
                            <div className="col-6 right-border"> 
                                <p className="pb-0 mb-0 text-white line-height-m">Share</p>    
                            </div> 
                            <div className="col-6 left-border">
                                <p className="pb-0 mb-0 text-white line-height-m">Social Media Platforms. Electroinic Devices.</p>
                            </div>    
                            </div>
                        </div>
                        <div className="divider-white mt-2 mb-2 mr-0 ml-0" style={{}} />
                        {/*  */}
                        {/* Returned and Expiry Policy */}
                        <div className="list-group list-custom-small mb-2">
                            <h6 className="font-500">Returned and Expiry Policy</h6>
                            <p className="pb-0 mb-0 font-11 line-height">This Orders Once placed will cancels with in 2 hours refund 95% amount</p>         
                            <p className="pb-0 mb-0 font-11 line-height">Orders Events Documentary Not Responses any Commutations</p>         
                            <p className="pb-0 mb-0 font-11 line-height">Cancel after 24 hours 70% Refund Amount</p>         
                            <p className="pb-0 mb-0 font-11 line-height">Cancel after 24 hours 70% Refund Amount</p>         
                            <p className="pb-0 mb-0 font-11 line-height">Cancel after 24 hours 70% Refund Amount</p>         
                            <p className="pb-0 mb-0 font-11 line-height">If You Not Propers Sent Documents Deadline Not Reach.our Not Responsibility</p>         
                        </div>
                        {/* Returned and Expiry Policy */}
                        </AccordionDetails>
                    </Accordion>
                </div>
                {/* Product Description */}

                {/* Product Description */}
                {/* <div className="content mb-0 mt-1">          
                    <div className="list-group list-custom-small list-icon-0">
                    <a data-toggle="collapse" href="#collapse-1" aria-expanded="true">
                        <i className="fa font-14 fa-eye color-white" />
                        <span className="font-14">Product Details</span>
                        <i className="fa fa-angle-down color-white" />
                    </a>        
                    </div>
                    <div className="collapse" id="collapse-1">
                    <div className="list-group list-custom-small mt-1">
                        <h5 className="font-600 font-15 mb-2">Media Specifications</h5>
                        <div className="row mb-0">
                        <div className="col-6 right-border"> 
                            <p className="pb-0 mb-0 text-white line-height-m">Product Duration</p>    
                            <p className="pb-0 mb-0 line-height">Trailor</p>      
                        </div> 
                        <div className="col-6 left-border">
                            <p className="pb-0 mb-0 text-white line-height-m">Product Varaitains Models</p>
                            <p className="pb-0 mb-0 line-height"> Modern</p>
                        </div>    
                        </div>
                    </div>
                    <div className="divider-white mt-2 mb-2 mr-0 ml-0" />
                    </div>
                    <div className="collapse" id="collapse-1">
                    <div className="list-group list-custom-small">                    
                        <div className="row mb-0">
                        <div className="col-6 right-border"> 
                            <p className="pb-0 mb-0 text-white line-height-m">Product Languages</p>    
                            <p className="pb-0 mb-0 line-height">English, Hindi, Telugu, Urdu</p>      
                        </div> 
                        <div className="col-6 left-border">
                            <p className="pb-0 mb-0 text-white line-height-m">Product Sound</p>
                            <p className="pb-0 mb-0 line-height">No Sound</p>
                        </div>    
                        </div>
                    </div>
                    <div className="divider-white mt-2 mb-2 mr-0 ml-0" style={{}} />
                    </div>
                    <div className="collapse" id="collapse-1">
                    <div className="list-group list-custom-small">
                        <h6 className="font-600 font-15 mb-2">Media Information</h6>                 
                        <div className="row mb-0">
                        <div className="col-6 right-border"> 
                            <p className="pb-0 mb-0 text-white line-height-m">Format Type</p>    
                            <p className="pb-0 mb-0 line-height">Digital Format [ video ]</p>      
                        </div> 
                        <div className="col-6 left-border">
                            <p className="pb-0 mb-0 text-white line-height-m">Format Size</p>
                            <p className="pb-0 mb-0 line-height">HD / FULL HD / 4K [ on selected based ]</p>
                        </div>    
                        </div>
                    </div>
                    <div className="divider-white mt-2 mb-2 mr-0 ml-0" style={{}} />
                    </div>
                    <div className="collapse" id="collapse-1">
                    <div className="list-group list-custom-small">
                        <div className="row mb-0">
                        <div className="col-6 right-border"> 
                            <p className="pb-0 mb-0 text-white line-height-m">Share</p>    
                        </div> 
                        <div className="col-6 left-border">
                            <p className="pb-0 mb-0 text-white line-height-m">Social Media Platforms. Electroinic Devices.</p>
                        </div>    
                        </div>
                    </div>
                    <div className="divider-white mt-2 mb-2 mr-0 ml-0" style={{}} />
                    </div>
                    <div className="collapse" id="collapse-1">
                    <div className="list-group list-custom-small mb-2">
                        <h6 className="font-500">Returned and Expiry Policy</h6>
                        <p className="pb-0 mb-0 font-11 line-height">This Orders Once placed will cancels with in 2 hours refund 95% amount</p>         
                        <p className="pb-0 mb-0 font-11 line-height">Orders Events Documentary Not Responses any Commutations</p>         
                        <p className="pb-0 mb-0 font-11 line-height">Cancel after 24 hours 70% Refund Amount</p>         
                        <p className="pb-0 mb-0 font-11 line-height">Cancel after 24 hours 70% Refund Amount</p>         
                        <p className="pb-0 mb-0 font-11 line-height">Cancel after 24 hours 70% Refund Amount</p>         
                        <p className="pb-0 mb-0 font-11 line-height">If You Not Propers Sent Documents Deadline Not Reach.our Not Responsibility</p>         
                    </div>
                    </div>
                </div> */}
                {/* Product Description */}

                <div className="divider-white mb-2 ml-2 mr-2" />

                {/*  */}
                <div className="content mb-2">
                    <div className="row text-center mb-1">
                    <div className="col-4 p-3 m-1 rounded custom-bg" data-menu="img-reference">
                        <img className="mb-2" src="assets/images/ark/image.png" width="34px" alt="" />
                        <h6 className="font-14 font-500">Image Reference</h6>
                    </div>
                    <div className="col-3 custom-bg p-3 m-1 rounded" data-menu="video-guide">
                        <img className="mb-2" src="assets/images/ark/play.png" width="34px" alt="" />
                        <h6 className="font-14 font-500">Guide Video</h6>
                    </div>
                    <div className="col-4 custom-bg p-3 m-1 rounded" data-menu="write-review">
                        <img className="mb-2" src="assets/images/ark/star.png" width="34px" alt="" />
                        <h6 className="font-14 font-500">Write Review</h6>
                    </div>
                    </div>
                </div>
                {/*  */}

                {/* buttons */}
                
                <div class="content mb-1">
                    <div className="row mb-0">
                        <div className="col-6">
                            <a href="#" className="btn btn-s btn-full mb-1 rounded-xs text-uppercase font-14 font-700 color-dark-dark shadow-s bg-gray-dark"><i className="fa fa-heart" /> FAVORITE </a>
                        </div>
                        <div className="col-6">
                            <a href="#" className="btn btn-s btn-full mb-1 rounded-xs text-uppercase font-14 font-700 color-dark-dark shadow-s bg-blue-dark" data-menu="menu-add-to-bag"><i className="fa fa-shopping-bag" /> Add to bag</a>
                        </div>
                    </div>
                </div>
                {/* buttons */}
            </div>
        </div>
    
        {/* Add to Bag button model */}
        <div className='theme-dark'>
            <div id="menu-add-to-bag" className="menu menu-box-bottom menu-box-detached rounded-m" data-menu-height={370} data-menu-effect="menu-parallax">
                <div className="menu-title">
                    <h1 className="my-0 py-0 font-20">Media Options</h1>
                    <a href="#" className="close-menu"><i className="fa fa-times-circle" /></a>
                </div>
                <div className="divider divider-margins mt-0 mb-0" />
                <div className="content mt-0">
                    <div className="divider divider-margins mb-2 ml-0 mr-0" />
                    <h6 className="font-500 font-15">Media Languages</h6>
                    <p className="mb-0 font-11">Select a Media Visual Language</p>
                    <div className="d-flex justify-content-around">
                        <a href="#" type="checkbox" className="m-1 btn btn-s btn-full mb-3 rounded-xs text-uppercase font-600 shadow-s bg-blue-dark">English</a>
                        <a href="#" className="m-1 btn btn-s btn-full mb-3 rounded-xs text-uppercase font-600 shadow-s custom-bg">Telugu</a>
                        <a href="#" className="m-1 btn btn-s btn-full mb-3 rounded-xs text-uppercase font-600 shadow-s custom-bg">Hindi</a>
                        <a href="#" className="m-1 btn btn-s btn-full mb-3 rounded-xs text-uppercase font-600 shadow-s custom-bg">Urdu</a>
                    </div>
                    <div className="divider divider-margins mb-2" />
                    <h6 className="font-500 font-15">Media Sound</h6>
                    <p className="mb-0 font-11">Select a Media Audio</p>
                    <div className="d-flex justify-content-around">
                        <a href="#" type="checkbox" className="m-1 btn btn-s btn-full mb-3 rounded-xs text-uppercase font-600 shadow-s bg-blue-dark">Sound Selected</a>
                        <a href="#" className="m-1 btn btn-s btn-full mb-3 rounded-xs text-uppercase font-600 shadow-s custom-bg">Default Sound</a>
                    </div>
                    <div className="divider divider-margins mb-2" />
                    <a href="cart" className="m-1 btn btn-s btn-full mb-3 rounded-xs text-uppercase font-600 shadow-s custom-bg">Done</a>
                </div>
            </div>
        </div>
        {/* Add to Bag button modal */}
        {/* image Reference modal */}
        <div id="img-reference" className="menu menu-box-bottom menu-box-detached rounded-m" data-menu-height={450} data-menu-effect="menu-parallax">
            <div className="menu-title ml-2 mr-2">
                <h4 className="my-0 py-0">Image Referance</h4>
                <a href="#" className="close-menu"><i className="fa fa-times-circle" /></a>
            </div>
            <div className="divider divider-margins mt-3 mb-0" />
            <div className="mt-3 mr-1 mb-3 ml-1">
                <a href="#" className="d-flex mb-2">
                    <div className="col-6 pl-0 pr-0">
                    <img src="assets/images/ark/digital.png" className="img-fluid mb-2" />
                    </div>
                    <div className="pl-2">
                    <h5>Card 01</h5>
                    <p className="mt-1 mb-1 font-11 line-height-s">Referance Picture Caputure angle and prespitavie as better aut put as come.</p>                                      
                    </div>
                </a>
            </div>
            <div className="mt-3 mr-1 mb-3 ml-1">
                <a href="#" className="d-flex mb-2">
                    <div className="col-6 pl-0 pr-0">
                    <img src="assets/images/ark/motion.png" className="img-fluid mb-2" />
                    </div>
                    <div className="pl-2">
                    <h5>Card 02</h5>
                    <p className="mt-1 mb-1 font-11 line-height-s">Referance Picture Caputure angle and prespitavie as better aut put as come.</p>                                      
                    </div>
                </a>
            </div>
            <div className="mt-3 mr-1 mb-3 ml-1">
                <a href="#" className="d-flex mb-2">
                    <div className="col-6 pl-0 pr-0">
                    <img src="assets/images/ark/stories.jpg" className="img-fluid mb-2" />
                    </div>
                    <div className="pl-2">
                    <h5>Card 03</h5>
                    <p className="mt-1 mb-1 font-11 line-height-s">Referance Picture Caputure angle and prespitavie as better aut put as come.</p>                                      
                    </div>
                </a>
            </div>
            <div className="mt-3 mr-1 mb-3 ml-1">
                <a href="#" className="d-flex mb-2">
                    <div className="col-6 pl-0 pr-0">
                    <img src="assets/images/ark/intro.jpg" className="img-fluid mb-2" />
                    </div>
                    <div className="pl-2">
                    <h5>Card 04</h5>
                    <p className="mt-1 mb-1 font-11 line-height-s">Referance Picture Caputure angle and prespitavie as better aut put as come.</p>                                      
                    </div>
                </a>
            </div>
        </div>
        {/* image reference modal */}
        {/* video guide modal */}
        <div id="video-guide" className="menu menu-box-bottom menu-box-detached rounded-m" data-menu-height={370} data-menu-effect="menu-parallax">
            <div className="menu-title ml-2 mr-2">
            <h5 className="my-0 py-0">Video Guide</h5>
            <a href="#" className="close-menu"><i className="fa fa-times-circle" /></a>
            </div>
            <div className="responsive-iframe max-iframe">
            <video width="100%" poster="assets/images/ark/digital.png" controls loop autoPlay muted>
                <source src="assets/images/ark/saree.mp4" type="video/mp4" />
                <source src="mov_bbb.ogg" type="video/ogg" />
                Your browser does not support HTML video.
            </video> 
            </div>             
        </div>
        {/* video guide modal */}
        {/* review modal */}
        <div id="write-review" className="menu menu-box-bottom menu-box-detached rounded-m" data-menu-height={520} data-menu-effect="menu-parallax">
            <div className="menu-title ml-2 mr-2">
            <h3 className="my-0 py-0">Write a Review</h3>
            <a href="#" className="close-menu"><i className="fa fa-times-circle" /></a>
            </div>
            <div className="divider divider-margins mt-3 mb-0" />
            <div className="mt-5 mr-3 mb-5 ml-3 text-center">
            <a href="#" className="d-flex mb-2">
                <div className='pl-5 pr-5'>
                    <h2 className='font-500 font-20'>Please rate the quality of service for the order!</h2>                                      
                </div>
            </a>
            <div className="mt-3 mb-3">                        
                <span className="fa fa-star font-16" />
                <span className="fa fa-star font-20" />
                <span className="fa fa-star font-26" />
                <span className="fa fa-star font-20" />
                <span className="fa fa-star font-16" />                      
            </div>
            <div className="mt-3">
                <span className="font-500">Your comments and suggestions help us improve the service quality better!</span>
            </div>
            <div className="input-style input-style-always-active has-borders no-icon validate-field mt-4">
                <textarea className="form-control text-white border border-secondary" placeholder defaultValue={""} />
                <label htmlFor="form1ab" className="color-theme text-uppercase font-700 font-10">comment</label>
                <i className="fa fa-times disabled invalid color-red-dark" />
                <i className="fa fa-check disabled valid color-green-dark" />
                <p className="text-left">maximum 120 words write comments</p>             
            </div>
            <div className="pt-2 pd-2 ml-2 mr-2">
                <a href="component-reviews.html">
                <button className="btn-bag rounded-xs text-uppercase font-700 shadow-s">Submit</button></a>
            </div>        
            </div>         
        </div>
        {/* review modal */}
        
    </>
  )
}
