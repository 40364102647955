import React from 'react'
import Footer from '../../landingpages/footer/footer'
import ConfirmOrderContent from './confirm_order_content'

export default function ConfirmOrder() {
  return (
    <>
        <ConfirmOrderContent />
        <Footer />
    </>
  )
}
