import React from 'react'

export default function Registration() {
  return (
    <>
        <div className='theme-dark'>
            <div id='page'>

                <div className="page-subtitle mt-1 mb-1 border-bottom">
                    <a href="#" className="page-subtitle-icon mt-1" data-back-button><i className="fa fa-arrow-left color-theme" /></a>
                    {/* <h1 class="page-video-title text-center">Sign Up</h1> */}
                </div>

                <div className="content">
                    <h5 className="font-25 font-600 text-center custom-forget">Sign Up</h5>
                    <form>
                    <label className="text-uppercase" htmlFor="form-label">User Name</label>
                    <div className="input-style input-style-2 has-icon input-required">
                        <i className="input-icon fa fa-user" />
                        <input className="form-control border border-secondary" type="email" placeholder="User Name" />
                    </div>
                    <label className="text-uppercase" htmlFor="form-label">Phone Number</label>
                    <div className="input-style input-style-2 has-icon input-required">
                        <i className="input-icon fa fa-phone" />
                        <input className="form-control border border-secondary" type="email" placeholder="Phone Number" />
                    </div>
                    <h6 className="text-uppercase mt-5 font-500">Adderss INFORMATION</h6>
                    <label className="text-uppercase" htmlFor="form-label">Address</label>
                    <div className="input-style input-style-2 has-icon input-required">
                        <i className="input-icon fa fa-address-book" />
                        <input className="form-control border border-secondary" type="email" placeholder="Enter your location" />
                    </div>
                    <label className="text-uppercase" htmlFor="form-label">City</label>
                    <div className="input-style input-style-2 has-icon input-required">
                        <i className="input-icon fa fa-map-marker" />
                        <input className="form-control border border-secondary" type="email" placeholder="City Name" />
                    </div>
                    <label className="text-uppercase" htmlFor="form-label">State</label>
                    <div className="input-style input-style-2 input-required border border-secondary rounded">
                        <select className="form-control custom-select font-14">
                        <option className="text-dark font-14" value="default" disabled selected>Select a State</option>
                        <option className="text-dark font-14" value="ap">AP</option>
                        <option className="text-dark font-14" value="ts">TS</option>
                        </select>
                    </div>
                    <label className="text-uppercase" htmlFor="form-label">Pincode</label>
                    <div className="input-style input-style-2 has-icon input-required">
                        <i className="input-icon fa fa-address-book" />
                        <input className="form-control border border-secondary" type="email" placeholder="Pincode" />
                    </div>
                    <label className="text-uppercase" htmlFor="form-label">Country</label>
                    <div className="input-style input-style-2 input-required border border-secondary rounded">
                        <select className="form-control custom-select font-14">
                        <option className="text-dark font-14" value="default" disabled selected>Select a Country</option>
                        <option className="text-dark font-14" value="ind">INDIA</option>
                        <option className="text-dark font-14" value="ca">CANADA</option>
                        </select>   
                    </div>
                    <a href="account_created" className="btn btn-center-l btn-xs shadow-xl bg-secondary font-700 font-16 text-uppercase mb-2 button-width-100">Sign Up</a>
                    <div className="text-center mt-3 mb-3 d-flex justify-content-between">
                        <span className="text-secondary font-100 mt-3 font-11">Already have an account ?
                            <a href='login'><span className="font-800 font-14 custom-forget">&nbsp;&nbsp;Sign In</span></a>
                        </span>
                        <a href className="text-white font-600 mt-3">Forgot Password?</a><br />
                    </div>
                    <div className="text-center"> 
                        <a href="#" className="mr-3"><img src="assets/images/ark/facebook.png" width={50} alt="" /></a>
                        <a href="#"><img src="assets/images/ark/google-plus.png" width={50} alt="" /></a>
                    </div>
                    </form>
                </div>

            </div>
        </div>
    </>
  )
}
