import React from 'react'

export default function ConfirmOrderContent() {
  return (
    <>
        <div id='page'>
            {/* Top fixed header */}
            <div className="page-subtitle mb-2 mt-2 border-bottom">
                <a href="#" className="page-subtitle-icon" data-back-button><i className="fa fa-arrow-left color-theme" /></a>
                <h1 className="font-18 font-500">Confirm Order</h1>
                <a href="#" className="page-subtitle-icon" data-menu="menu-main"><i className="fa fa-bars color-theme" /></a>
            </div> 
            {/* Top fixed header */}

                {/* Top header it appearers when Scroll down the page */}
            <div className="header header-fixed header-logo-app header-auto-show">
                <a href="index.html" className="header-title font-14">Confirm Order</a>
                <a href="#" data-back-button className="header-icon header-icon-1 pt-3"><i className="fas fa-arrow-left" /></a>
                <a href="#" data-menu="menu-main" className="header-icon header-icon-2 pt-3"><i className="fas fa-bars" /></a>
            </div>
            {/* Top header it appearers when Scroll down the page */}
            
            <div className='page-content'>                
                <div className="content mb-0">
                    <div className="row mb-2">
                        <div className="col-7 d-flex">
                            <i className="fa fa-user color-white-dark custom-user" />
                            <select className="form-control custom-select font-14">
                            <option className="text-dark font-14" value="default" disabled selected>Select a Value</option>
                            <option className="text-dark font-14" value="iOS">iOS</option>
                            <option className="text-dark font-14" value="Linux">Linux</option>
                            <option className="text-dark font-14" value="MacOS">MacOS</option>
                            <option className="text-dark font-14" value="Android">Android</option>
                            <option className="text-dark font-14" value="Windows">Windows</option>
                            </select>
                        </div>
                        <div className="col-5 text-right">
                            <button className="btn btn-xs text-uppercase font-500 shadow-s bg-highlight"> <i className="fa fa-user-plus" /> ADD USER</button>
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-12 d-flex justify-content-between">
                            <span className="font-10"><i className="fa fa-phone" /> +919876543210</span>
                            <span className="font-10"><i className="fa fa-envelope" /> sample@gmail.com</span>
                        </div>
                        <div className="col-12 d-flex justify-content-between align-content-center">
                            <span className="font-10"><i className="fa fa-map-marker" /> Address,City,State,Pincode</span>
                            <button className="border-green-dark btn btn-border btn-xxs color-green-dark font-600 mb-1 rounded-xl text-sentance">&nbsp;&nbsp;&nbsp; Self &nbsp;&nbsp;&nbsp;</button>
                        </div>
                    </div>
                </div>

                <div className="divider divider-margins mb-3" />
                {/* product list start */}
                <div className="content m-2">
                    <div className="d-flex mb-2">
                        <div className="col-6 p-0">
                            <video width="100%" height="100%" poster="assets/images/ark/digital.png" controls loop muted>
                            <source src="assets/images/ark/saree.mp4" type="video/mp4" />
                            <source src="mov_bbb.ogg" type="video/ogg" />
                            Your browser does not support HTML video.
                            </video>                     
                        </div>
                        <div className="col-6 pl-2 pr-2" style={{lineHeight: '10px'}}>
                            <a href='product_details'>
                            <h5 className="font-400 mb-0 font-14">Wedding Design name</h5>
                            <span className="font-10"><del>$50.00</del></span>
                            <span className="font-weight-bold font-14">$30.00</span>
                            <span className="font-10" style={{color: '#f9999f !important'}}>$20 OFF</span> 
                            </a>
                            <div>
                                <p className="mb-0 mt-1 font-8 line-height text-primary text-uppercase">Type of delivery</p>
                                <p className="mb-0 font-12 font-600 line-height text-grey">Standard <i className="fa fa-check-circle color-blue-dark" /> </p>
                                <p className="mb-0 font-8 line-height">Bellows 24 Hours Delivery for approvals Drat</p>
                                <p className="mb-0 font-12 font-600 line-height text-grey">Express</p>
                                <p className="mb-0 font-8 line-height">6 Hours Delivery for approvals Drat</p>
                                <p className="mb-0 font-8 line-height text-danger">*Note - After Received Media Information / Documents above Start Delivery</p>
                            </div> 
                        </div>
                    </div>
                    <div className="d-flex mb-2">
                        <div className="col-6 p-0">
                            <video width="100%" height="100%" poster="assets/images/ark/digital.png" controls loop muted>
                            <source src="assets/images/ark/saree.mp4" type="video/mp4" />
                            <source src="mov_bbb.ogg" type="video/ogg" />
                            Your browser does not support HTML video.
                            </video>                     
                        </div>
                        <div className="col-6 pl-2 pr-2" style={{lineHeight: '10px'}}>
                            <a href='product_details'>
                                <h5 className="font-400 mb-0 font-14">Wedding Design name</h5>
                                <span className="font-10"><del>$50.00</del></span>
                                <span className="font-weight-bold font-14">$30.00</span>
                                <span className="font-10" style={{color: '#f9999f !important'}}>$20 OFF</span> 
                            </a>
                            <div>
                                <p className="mb-0 mt-1 font-8 line-height text-primary text-uppercase">Type of delivery</p>
                                <p className="mb-0 font-12 font-600 line-height text-grey">Standard <i className="fa fa-check-circle color-blue-dark" /> </p>
                                <p className="mb-0 font-8 line-height">Bellows 24 Hours Delivery for approvals Drat</p>
                                <p className="mb-0 font-12 font-600 line-height text-grey">Express</p>
                                <p className="mb-0 font-8 line-height">6 Hours Delivery for approvals Drat</p>
                                <p className="mb-0 font-8 line-height text-danger">*Note - After Received Media Information / Documents above Start Delivery</p>
                            </div> 
                        </div>
                    </div>
                    <div className="d-flex mb-2">
                        <div className="col-6 p-0">
                            <video width="100%" height="100%" poster="assets/images/ark/digital.png" controls loop muted>
                            <source src="assets/images/ark/saree.mp4" type="video/mp4" />
                            <source src="mov_bbb.ogg" type="video/ogg" />
                            Your browser does not support HTML video.
                            </video>                     
                        </div>
                        <div className="col-6 pl-2 pr-2" style={{lineHeight: '10px'}}>
                            <a href='product_details'>
                                <h5 className="font-400 mb-0 font-14">Wedding Design name</h5>
                                <span className="font-10"><del>$50.00</del></span>
                                <span className="font-weight-bold font-14">$30.00</span>
                                <span className="font-10" style={{color: '#f9999f !important'}}>$20 OFF</span> 
                            </a>
                            <div>
                                <p className="mb-0 mt-1 font-8 line-height text-primary text-uppercase">Type of delivery</p>
                                <p className="mb-0 font-12 font-600 line-height text-grey">Standard <i className="fa fa-check-circle color-blue-dark" /> </p>
                                <p className="mb-0 font-8 line-height">Bellows 24 Hours Delivery for approvals Drat</p>
                                <p className="mb-0 font-12 font-600 line-height text-grey">Express</p>
                                <p className="mb-0 font-8 line-height">6 Hours Delivery for approvals Drat</p>
                                <p className="mb-0 font-8 line-height text-danger">*Note - After Recived Media Information / Documents above Start Delivery</p>
                            </div> 
                        </div>
                    </div>
                    <div className="d-flex mb-2">
                        <div className="col-6 p-0">
                            <video width="100%" height="100%" poster="assets/images/ark/digital.png" controls loop muted>
                            <source src="assets/images/ark/saree.mp4" type="video/mp4" />
                            <source src="mov_bbb.ogg" type="video/ogg" />
                            Your browser does not support HTML video.
                            </video>                     
                        </div>
                        <div className="col-6 pl-2 pr-2" style={{lineHeight: '10px'}}>
                            <a href='product_details'>
                                <h5 className="font-400 mb-0 font-14">Wedding Design name</h5>
                                <span className="font-10"><del>$50.00</del></span>
                                <span className="font-weight-bold font-14">$30.00</span>
                                <span className="font-10" style={{color: '#f9999f !important'}}>$20 OFF</span> 
                            </a>
                            <div>
                                <p className="mb-0 mt-1 font-8 line-height text-primary text-uppercase">Type of delivery</p>
                                <p className="mb-0 font-12 font-600 line-height text-grey">Standard <i className="fa fa-check-circle color-blue-dark" /> </p>
                                <p className="mb-0 font-8 line-height">Bellows 24 Hours Delivery for approvals Drat</p>
                                <p className="mb-0 font-12 font-600 line-height text-grey">Express</p>
                                <p className="mb-0 font-8 line-height">6 Hours Delivery for approvals Drat</p>
                                <p className="mb-0 font-8 line-height text-danger">*Note - After Recived Media Information / Documents above Start Delivery</p>
                            </div> 
                        </div>
                    </div>
                </div>
                {/* product list end */}

                <div className="divider divider-margins mb-2 ml-0 mr-0" />

                {/* Pricing details start */}
                <div className="content mb-0 mt-1">
                    <h5>PRICE DETAILS [5 ITEMS]</h5>
                    <div className="d-flex justify-content-between">
                    <p className="mb-1"><span>Total</span> <span className="ml-5">MRP</span></p>
                    <p>$200.00</p>
                    </div>
                    <div className="d-flex justify-content-between">
                    <p className="mb-2"><span>Discount</span> &nbsp; <span className="ml-3">MRP</span></p>
                    <p>$50.00</p>
                    </div>
                    <div className="d-flex justify-content-between">
                    <p className="mb-2"><span className="font-600 font-15">Coupon Apply [ARK10]</span></p>
                    <p>$10.00</p>
                    </div>
                    <div className="divider divider-margins mb-2 ml-0 mr-0" />
                    <div className="d-flex justify-content-between">
                    <p className="mb-2"><span>TOTAL AMOUNT</span></p>
                    <p>$140.00</p>
                    </div>
                    <div className="divider divider-margins mb-2 ml-0 mr-0" />
                    <a href="#" data-menu="menu-option-1" className="btn btn-center-l btn-xs shadow-xl bg-highlight font-700 font-16 text-uppercase mb-2 button-width-100">CONFIRM ORDER</a>
                    <div className="divider divider-margins mb-0 ml-0 mr-0" />
                </div>
                {/* Pricing details end */}
            </div>
        </div>            

        {/* Payment success or cancel model start */}
        <div id="menu-option-1" className="menu menu-box-bottom menu-box-detached rounded-m theme-dark" data-menu-height={210} data-menu-effect="menu-parallax">
            <div className="menu-title">
            <h1 className="my-0 py-0">Are you Sure?</h1>
            <a href="#" className="close-menu"><i className="fa fa-times-circle" /></a>
            </div>
            <div className="content mt-n3">
            <p className="pr-3">
                Please confirm before proceeding to the payment mode.
            </p>
            <div className="divider" />
            <div className="row mb-0">
                <div className="col-6">
                <a href="order_failed" className="btn btn-full btn-m bg-red-dark font-800 text-uppercase rounded-s">No,
                    cancel</a>
                </div>
                <div className="col-6">
                <a href="order_success" className="btn btn-full btn-m bg-green-dark font-800 text-uppercase rounded-s">Yes,
                    proceed!</a>
                </div>
            </div>
            </div>
        </div>
        {/* Payment success or cancel model end */}
    </>
  )
}
