import React from 'react'
import Footer from '../landingpages/footer/footer'
import SubCategoryContent from './sub_category_content'

export default function SubCategory() {
  return (
    <>
        <SubCategoryContent />
        <Footer />
    </>
  )
}
