import React from 'react'

export default function AccountCreated() {
  return (
    <>
        <div id='page'>
            <div className="page-content pb-0 mb-0">
                <div className="card" data-card-height="cover">
                    <div className="card-center px-4">
                        <img src="assets/images/ark/as-bg.png" alt="" width="360px" className="d-block m-auto" />
                        <h1 className="font-500 text-center mt-4" style={{letterSpacing: '0.2px'}}>Account Created !</h1>      
                        <h3 className="text-center font-400 text-secondary mt-3 font-16">Your account had beed created successfully.</h3> 
                        <div className="text-center">     
                        <a href="/" className="btn btn-full btn-xs bg-white rounded-sm mt-2 font-400 text-dark">Shop Now</a>
                        </div>            
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}
