import React from 'react'
import Footer from '../../landingpages/footer/footer'
import ProductDetailsContent from './product_details_content'

export default function ProductDetails() {
  return (
    <>
        <ProductDetailsContent />
        <Footer />
    </>
  )
}
