import React from 'react'

export default function OrderSuccessContent() {
  return (
    <>
        <div id='page'>
            {/* Top fixed header */}
            <div className="page-subtitle mb-2 mt-2 border-bottom">
                <a href="#" className="page-subtitle-icon" data-back-button><i className="fa fa-arrow-left color-theme" /></a>
                <h1 className="font-18 font-500"></h1>
                <a href="#" className="page-subtitle-icon" data-menu="menu-main"><i className="fa fa-bars color-theme" /></a>
            </div> 
            {/* Top fixed header */}
            {/* page content start */}
            <div className="page-content pb-0 mb-0">
                <div className="card theme-dark" data-card-height="500">
                    <div className="card-center px-4" style={{top: '45%'}}>
                        <img src="assets/images/ark/ok.png" alt="" width="230px" className="d-block m-auto" />
                        <h1 className="font-500 text-center mt-2" style={{letterSpacing: '0.2px'}}>Thank you for your order!</h1>      
                        <h3 className="text-center font-400 text-secondary mt-2" style={{fontSize: '16px'}}>Your order will be delivered on time.</h3> 
                        <div className="text-center">     
                            <a href="#" className="btn btn-full btn-xs bg-dark rounded-sm mt-2 font-400  shadow-xl" style={{borderRadius: '5px !important', fontSize: '15px'}}>View Orders</a>
                        </div>
                        <a href="/" className="btn btn-full btn-xs bg-white rounded-sm mt-2 font-400 text-dark" style={{borderRadius: '5px !important', fontSize: '13px'}}>CONTINUE SHOPPING</a>
                    </div>
                </div>
            </div>
            {/* page content end */}
        </div>
    </>
  )
}
