import React from 'react'
import Footer from '../../landingpages/footer/footer'
import ProductListContent from './product_list_content'

export default function ProductList() {
  return (
    <>
        <ProductListContent />
        <Footer />
    </>
  )
}
