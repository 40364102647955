import React from 'react'

export default function SubCategoryContent() {
  return (
    <>
        <div id='page'>
            <div className="header header-fixed header-logo-app">
                <a href="#" data-back-button className="header-icon header-icon-1"><i className="fa fa-arrow-left color-theme" /></a>
                <a href="cart" ata-toggle-theme className="header-icon header-icon-2"><img className="main-header-icon-right mt-1" src="assets/images/ark/shopping-bag.png" alt="" width="35px" /></a>
                <a href="#" data-toggle-theme className="header-icon header-icon-3"><img className="main-header-icon-right mt-1" src="assets/images/ark/bell_white.png" alt="" width="30px" /></a>
                <a href="#" data-toggle-theme className="header-icon header-icon-4"><img className="main-header-icon-right mt-1" src="assets/images/ark/magnifying-glass.png" alt="" width="32px" /></a>
            </div>

            {/* Page content starts */}
            <div className="page-content header-clear">
                <div className='content'>
                    {/* carousel start */}
                    <div className="single-slider owl-no-controls owl-carousel owl-no-dots">
                        <div className='item'>
                            <video className="custom-video-width" poster="assets/images/ark/digital.png" controls loop muted>
                            <source src="assets/images/ark/saree.mp4" type="video/mp4" />
                            <source src="mov_bbb.ogg" type="video/ogg" />
                            Your browser does not support HTML video.
                            </video>
                        </div>
                        <div className='item'>
                            <video className="custom-video-width" poster="assets/images/ark/digital.png" controls loop muted>
                            <source src="assets/images/ark/saree.mp4" type="video/mp4" />
                            <source src="mov_bbb.ogg" type="video/ogg" />
                            Your browser does not support HTML video.
                            </video>
                        </div>
                        <div className='item'>
                            <video className="custom-video-width" poster="assets/images/ark/digital.png" controls loop muted>
                            <source src="assets/images/ark/saree.mp4" type="video/mp4" />
                            <source src="mov_bbb.ogg" type="video/ogg" />
                            Your browser does not support HTML video.
                            </video>
                        </div>
                    </div>
                </div>
                <div className='content ml-1 mr-1'>
                    {/* carousel end */}
                    <h3 className="ml-3 mt-4 mb-1">SUB CATEGORIES</h3>
                    <div className="divider bg-blue-dark divider-margins ml-3 mb-1" style={{width: '35%'}} />
                    <div className="divider bg-blue-dark divider-margins ml-4 mb-2" style={{width: '37%'}} />
                    {/* carousel Designs Options Tag start */}
                    <div className="double-slider1 owl-carousel owl-no-dots">
                        <div className="item">
                            <a href="products">
                                <div data-card-height={150} style={{backgroundImage: 'url("assets/images/ark/digital.jpg")'}} className="card bg-6 rounded-m shadow-l mb-2">
                                    <div className="card-bottom text-center mb-2">
                                        <h2 className="color-white text-uppercase font-500 font-14">Wedding</h2>
                                    </div>
                                    <div className="card-overlay bg-gradient" />
                                </div>
                            </a>
                        </div>
                        <div className="item">
                            <a href="products">
                                <div data-card-height={150} style={{backgroundImage: 'url("assets/images/ark/latest1.jpg")'}} className="card bg-27 rounded-m shadow-l mb-2">
                                    <div className="card-bottom text-center mb-2">
                                        <h2 className="color-white text-uppercase font-500 font-14">Engagement</h2>
                                    </div>
                                    <div className="card-overlay bg-gradient" />
                                </div>
                            </a>
                        </div>
                        <div className="item">
                            <a href="products">
                                <div data-card-height={150} style={{backgroundImage: 'url("assets/images/ark/event.jpg")'}} className="card bg-20 rounded-m shadow-l mb-2">
                                    <div className="card-bottom text-center mb-2">
                                        <h2 className="color-white text-uppercase font-500 font-14">House warming</h2>
                                    </div>
                                    <div className="card-overlay bg-gradient" />
                                </div>
                            </a>
                        </div>
                        <div className="item">
                            <a href="products">
                                <div data-card-height={150} style={{backgroundImage: 'url("assets/images/ark/wedding.jpg")'}} className="card bg-20 rounded-m shadow-l mb-2">
                                    <div className="card-bottom text-center mb-2">
                                        <h2 className="color-white text-uppercase font-500 font-14">Half Saree</h2>
                                    </div>
                                    <div className="card-overlay bg-gradient" />
                                </div>
                            </a>
                        </div>
                        <div className="item">
                            <a href='products'>
                                <div data-card-height={150} style={{backgroundImage: 'url("assets/images/ark/latest1.jpg")'}} className="card bg-20 rounded-m shadow-l mb-2">
                                    <div className="card-bottom text-center mb-2">
                                        <h2 className="color-white text-uppercase font-500 font-14">Special Events</h2>
                                    </div>
                                    <div className="card-overlay bg-gradient" />
                                </div>
                            </a>
                        </div>
                    </div>
                    {/* carousel Designs Options Tag end */}

                    {/* Offers box starts */}
                    <div className="content border border-rounded border-secondary rounded pt-2 pl-3 pr-3 pb-2 ofr-box m-2 mb-3" style={{background: 'linear-gradient(90deg, hsla(233, 100%, 90%, 1) 0%, hsla(0, 0%, 89%, 1) 100%)'}}>
                        <a href="offers.html">
                        <h5><img src="assets/images/ark/logo-bg.png" style={{width: '40px'}} alt="" />
                            <span className="m-1 blink-1">DISCOUNT OFFERS</span>
                            <span className="float-right font-weight-normal border border-dark rounded pr-2 pl-2 mt-1 text-white" style={{fontSize: '13px', backgroundColor: '#000e82f0'}}>
                            View All
                            </span>
                        </h5>
                        </a>
                        <span>
                        Get best deals with great discounts.
                        </span>
                    </div>
                    {/* Offers box ends */}

                    <h3 className="ml-1 mt-2 mb-0 font-17 font-500">Invitation Designs Options Tag</h3>
                    <div className="divider bg-blue-dark divider-margins ml-1 mb-2" style={{width: '45%'}} />
                    {/* carousel Designs Options Tag start */}
                    <div className="double-slider1 owl-carousel owl-no-dots">
                        <a href="products">
                            <div className="item">
                                <div data-card-height={130} style={{backgroundImage: 'url("assets/images/ark/latest1.jpg")'}} className="card bg-6 shadow-l mb-2">
                                    <div className="card-bottom text-center">
                                        <h2 className="color-white text-uppercase font-500 font-14">Hindu</h2>
                                    </div>
                                    <div className="card-overlay bg-gradient" />
                                </div>
                            </div>
                        </a>
                        <a href='products'>
                            <div className="item">
                                <div data-card-height={130} style={{backgroundImage: 'url("assets/images/ark/event.jpg")'}} className="card bg-6 shadow-l mb-2">
                                    <div className="card-bottom text-center">
                                        <h2 className="color-white text-uppercase font-500 font-14">Muslim</h2>
                                    </div>
                                    <div className="card-overlay bg-gradient" />
                                </div>
                            </div>
                        </a>
                        <a href='products'>
                            <div className="item">
                                <div data-card-height={130} style={{backgroundImage: 'url("assets/images/ark/latest1.jpg")'}} className="card bg-6 shadow-l mb-2">
                                    <div className="card-bottom text-center">
                                        <h2 className="color-white text-uppercase font-500 font-14">Christian</h2>
                                    </div>
                                    <div className="card-overlay bg-gradient" />
                                </div>
                            </div>
                        </a>
                        <a href='products'>
                            <div className="item">
                                <div data-card-height={130} style={{backgroundImage: 'url("assets/images/ark/digital.jpg")'}} className="card bg-6 shadow-l mb-2">
                                    <div className="card-bottom text-center">
                                        <h2 className="color-white text-uppercase font-500 font-14">Universal</h2>
                                    </div>
                                    <div className="card-overlay bg-gradient" />
                                </div>
                            </div>
                        </a>
                        <a href='products'>
                            <div className="item">
                                <div data-card-height={130} style={{backgroundImage: 'url("assets/images/ark/latest1.jpg")'}} className="card bg-6 shadow-l mb-2">
                                    <div className="card-bottom text-center">
                                        <h2 className="color-white text-uppercase font-500 font-14">General</h2>
                                    </div>
                                    <div className="card-overlay bg-gradient" />
                                </div>
                            </div>
                        </a>
                    </div>
                    {/* carousel Designs Options Tag end */}  

                    <h3 className="ml-1 mt-2 mb-0 font-17 font-500">Engagement Designs Options Tag</h3>
                    <div className="divider bg-blue-dark divider-margins ml-1 mb-2" style={{width: '45%'}} />                      

                    {/* carousel Designs Options Tag start */}  
                    <div className="double-slider1 owl-carousel owl-no-dots">
                        <a href="products">
                            <div className="item">
                                <div data-card-height={130} style={{backgroundImage: 'url("assets/images/ark/event.jpg")'}} className="card bg-6 shadow-l mb-2">
                                    <div className="card-bottom text-center">
                                        <h2 className="color-white text-uppercase font-500 font-14">Hindu</h2>
                                    </div>
                                    <div className="card-overlay bg-gradient" />
                                </div>
                            </div>
                        </a>
                        <a href="products">
                            <div className="item">
                                <div data-card-height={130} style={{backgroundImage: 'url("assets/images/ark/digital.jpg")'}} className="card bg-6 shadow-l mb-2">
                                    <div className="card-bottom text-center">
                                        <h2 className="color-white text-uppercase font-500 font-14">Muslim</h2>
                                    </div>
                                    <div className="card-overlay bg-gradient" />
                                </div>
                            </div>
                        </a>
                        <a href="products">
                            <div className="item">
                                <div data-card-height={130} style={{backgroundImage: 'url("assets/images/ark/latest1.jpg")'}} className="card bg-6 shadow-l mb-2">
                                    <div className="card-bottom text-center">
                                        <h2 className="color-white text-uppercase font-500 font-14">Christian</h2>
                                    </div>
                                    <div className="card-overlay bg-gradient" />
                                </div>
                            </div>
                        </a>
                        <a href="products">
                            <div className="item">
                                <div data-card-height={130} style={{backgroundImage: 'url("assets/images/ark/digital.jpg")'}} className="card bg-6 shadow-l mb-2">
                                    <div className="card-bottom text-center">
                                        <h2 className="color-white text-uppercase font-500 font-14">Universal</h2>
                                    </div>
                                    <div className="card-overlay bg-gradient" />
                                </div>
                            </div>
                        </a>
                        <a href="products">
                            <div className="item">
                                <div data-card-height={130} style={{backgroundImage: 'url("assets/images/ark/concert.jpg")'}} className="card bg-6 shadow-l mb-2">
                                    <div className="card-bottom text-center">
                                        <h2 className="color-white text-uppercase font-500 font-14">General</h2>
                                    </div>
                                    <div className="card-overlay bg-gradient" />
                                </div>
                            </div>
                        </a>
                    </div>
                    {/* carousel Designs Options Tag end */}  

                    <h3 className="ml-1 mt-2 mb-0 font-17 font-500">Wedding Designs Options Tag</h3>
                    <div className="divider bg-blue-dark divider-margins ml-1 mb-2" style={{width: '45%'}} />

                    {/* carousel Designs Options Tag start */}  
                    <div className="double-slider1 owl-carousel owl-no-dots">
                        <a href="products">
                            <div className="item">
                                <div data-card-height={130} style={{backgroundImage: 'url("assets/images/ark/digital.jpg")'}} className="card bg-6 shadow-l mb-2">
                                    <div className="card-bottom text-center">
                                        <h2 className="color-white text-uppercase font-500 font-14">Hindu</h2>
                                    </div>
                                    <div className="card-overlay bg-gradient" />
                                </div>
                            </div>
                        </a>
                        <a href="products">
                            <div className="item">
                                <div data-card-height={130} style={{backgroundImage: 'url("assets/images/ark/digital.jpg")'}} className="card bg-6 shadow-l mb-2">
                                    <div className="card-bottom text-center">
                                        <h2 className="color-white text-uppercase font-500 font-14">Muslim</h2>
                                    </div>
                                    <div className="card-overlay bg-gradient" />
                                </div>
                            </div>
                        </a>
                        <a href="products">
                            <div className="item">
                                <div data-card-height={130} style={{backgroundImage: 'url("assets/images/ark/digital.jpg")'}} className="card bg-6 shadow-l mb-2">
                                    <div className="card-bottom text-center">
                                        <h2 className="color-white text-uppercase font-500 font-14">Christian</h2>
                                    </div>
                                    <div className="card-overlay bg-gradient" />
                                </div>
                            </div>
                        </a>
                        <a href="products">
                            <div className="item">
                                <div data-card-height={130} style={{backgroundImage: 'url("assets/images/ark/digital.jpg")'}} className="card bg-6 shadow-l mb-2">
                                    <div className="card-bottom text-center">
                                        <h2 className="color-white text-uppercase font-500 font-14">Universal</h2>
                                    </div>
                                    <div className="card-overlay bg-gradient" />
                                </div>
                            </div>
                        </a>
                        <a href="products">
                            <div className="item">
                                <div data-card-height={130} style={{backgroundImage: 'url("assets/images/ark/digital.jpg")'}} className="card bg-6 shadow-l mb-2">
                                    <div className="card-bottom text-center">
                                        <h2 className="color-white text-uppercase font-500 font-14">General</h2>
                                    </div>
                                    <div className="card-overlay bg-gradient" />
                                </div>
                            </div>
                        </a>
                    </div>
                    {/* carousel Designs Options Tag end */}  
                </div>
            </div>
        </div>
    </>
  )
}
