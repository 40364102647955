import React from 'react';
import { useEffect } from 'react';

const UseScript = (url) => {
    useEffect(() => {
        const script = document.createElement('script');
  
        script.src = url;
        script.async = true;
  
        document.body.appendChild(script);
  
        return () => {
          document.body.removeChild(script);
        };
    }, [url]);
  };

export default function Footer() {
    UseScript("/assets/scripts/jquery.js")
    UseScript("/assets/scripts/bootstrap.min.js")
    UseScript("/assets/scripts/custom.js")
  return (
    <>
        <div id="footer-bar" class="footer-bar-4">
            <a href="/"><img src='assets/images/ark/home.png' alt='home' style={{width: "30px"}}></img></a>
            <a href="#"><img src='assets/images/ark/category.png' alt='category' style={{width: "30px"}}></img></a>
            <a href="#"><img src='assets/images/ark/button.png' alt='button' style={{width: "35px"}}></img></a>
            <a href="login"><img src='assets/images/ark/login.png' alt='login' style={{width: "40px"}}></img></a>
        </div>
    </>
  )
}
