import React from 'react'
import Footer from '../footer/footer'
import HomeContent from './home_content'

export default function Home() {
  return (
    <>
        <HomeContent />
        <Footer />
    </>
  )
}
