import React from 'react'

export default function ReviewContent() {
  return (
    <>
        <div id='page'>
            {/* Top fixed header */}
            <div className="page-subtitle mb-2 mt-2 border-bottom">
                <a href="#" className="page-subtitle-icon" data-back-button><i className="fa fa-arrow-left color-theme" /></a>
                <h1 className="font-18 font-500">Reviews</h1>
                <a href="#" className="page-subtitle-icon" data-menu="menu-main"><i className="fa fa-bars color-theme" /></a>
            </div> 
            {/* Top fixed header */}

            {/* Top header it appearers when Scroll down the page */}
            <div className="header header-fixed header-logo-app header-auto-show">
                <a href="index.html" className="header-title font-14">Reviews</a>
                <a href="#" data-back-button className="header-icon header-icon-1"><i className="fas fa-arrow-left" /></a>
                <a href="#" data-menu="menu-main" className="header-icon header-icon-2"><i className="fas fa-bars" /></a>
            </div>
            {/* Top header it appearers when Scroll down the page */}

            {/* page content start */}
            <div className='page-content'>
                <div className='review-content'>
                    <div className="d-flex content mt-0 mb-1">
                        {/* right side of profile. increase image width to increase column size*/}
                        <div>
                            <img src="assets/images/ark/ark.png" data-src="assets/images/ark/ark.png" width={50} className="rounded-circle mr-3 shadow-xl preload-img" />
                        </div>
                        {/* left side of profile */}
                        <div className="flex-grow-1 line-height-xs">
                        <h5 className="mb-n1">Absolutely Gorgeous</h5>
                        <p className="font-10 mb-0 mt-2 opacity-40 text-left">31th October 2022</p>
                        <span>
                            <i className="fa fa-star color-yellow-dark" />
                            <i className="fa fa-star color-yellow-dark" />
                            <i className="fa fa-star color-yellow-dark" />
                            <i className="fa fa-star color-yellow-dark" />
                            <i className="fa fa-star color-yellow-dark" />
                        </span>
                        </div>            
                    </div>  

                    <div className="content mb-2 mt-2">
                        <p className="mt-2">
                            The ratings are not only for its amazing beautiful design but also these are for the queries they respond quickly. I am not any developer or know any codes. But with some trial and error I was able to update these theme on for my website. Also At times when I was not able to overcome with some issues I contacted the support team to help me. They managed to provide me solution quickly. At this price one Must definately try it.
                        </p>
                    </div>
                <div class="divider divider-margins mb-3"></div>
                </div>
                <div className='review-content'>
                    <div className="d-flex content mt-0 mb-1">
                        {/* right side of profile. increase image width to increase column size*/}
                        <div>
                            <img src="assets/images/ark/ark.png" data-src="assets/images/ark/ark.png" width={50} className="rounded-circle mr-3 shadow-xl preload-img" />
                        </div>
                        {/* left side of profile */}
                        <div className="flex-grow-1 line-height-xs">
                        <h5 className="mb-n1">Absolutely Gorgeous</h5>
                        <p className="font-10 mb-0 mt-2 opacity-40 text-left">31th October 2022</p>
                        <span>
                            <i className="fa fa-star color-yellow-dark" />
                            <i className="fa fa-star color-yellow-dark" />
                            <i className="fa fa-star color-yellow-dark" />
                            <i className="fa fa-star color-yellow-dark" />
                            <i className="fa fa-star color-yellow-dark" />
                        </span>
                        </div>            
                    </div>  

                    <div className="content mb-2 mt-2">
                        <p className="mt-2">
                            The ratings are not only for its amazing beautiful design but also these are for the queries they respond quickly. I am not any developer or know any codes. But with some trial and error I was able to update these theme on for my website. Also At times when I was not able to overcome with some issues I contacted the support team to help me. They managed to provide me solution quickly. At this price one Must definately try it.
                        </p>
                    </div>
                <div class="divider divider-margins mb-3"></div>
                </div>
            </div>
            {/* page content end */}
        </div>
    </>
  )
}
