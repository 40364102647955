import React from 'react'
import EmptyCartContent from './empty_cart_content'
import Footer from '../../landingpages/footer/footer'

export default function EmptyCart() {
  return (
    <>
        <EmptyCartContent />
        <Footer />
    </>
  )
}
