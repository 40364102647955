import React from 'react'

export default function CartContent() {
  return (
    <>
        <div id='page'>
            {/* Top fixed header */}
            <div className="page-subtitle mb-2 mt-2 border-bottom">
                <a href="#" className="page-subtitle-icon" data-back-button><i className="fa fa-arrow-left color-theme" /></a>
                <h1 className="font-18 font-500">Cart</h1>
                <a href="#" className="page-subtitle-icon" data-menu="menu-main"><i className="fa fa-bars color-theme" /></a>
            </div> 
            {/* Top fixed header */}

            {/* Top header it appearers when Scroll down the page */}
            <div className="header header-fixed header-logo-app header-auto-show">
                <a href="index.html" className="header-title font-14">Cart</a>
                <a href="#" data-back-button className="header-icon header-icon-1"><i className="fas fa-arrow-left" /></a>
                <a href="#" data-menu="menu-main" className="header-icon header-icon-2"><i className="fas fa-bars" /></a>
            </div>
            {/* Top header it appearers when Scroll down the page */}

            <div className='page-content'>
                {/* Product Details List End */}
                <div className="content mt-3 mb-1">
                    <div className="row mb-0">
                        <div className="d-flex mb-2">
                            <div className="col-7 pl-1">
                            <video width="100%" poster="assets/images/ark/digital.png" controls loop muted>
                                <source src="assets/images/ark/saree.mp4" type="video/mp4" />
                                <source src="mov_bbb.ogg" type="video/ogg" />
                                Your browser does not support HTML video.
                            </video>
                            </div>
                            <div className="col-5 p-0">
                            <a href='product_details'>
                                <h5 className="font-500 font-14">Wedding Design name</h5>
                                <p className="mt-n2 mb-1 font-11">WED00000001</p>
                            </a>
                            <button className="border-info btn btn-border btn-xxs text-info font-600 mb-1 rounded-xl text-sentance">Modren</button>
                            <button className="border-info btn btn-border btn-xxs text-info font-600 mb-1 rounded-xl text-sentance">Hindus</button>
                            <del className="font-11" style={{color: '#f37795'}}>$50.00</del>&nbsp;<span className="font-500 font-14">$37.88</span>&nbsp;<span className="font-11" style={{color: 'aquamarine !important'}}>-60%OFF</span>
                            <div>
                                <span>
                                <i className="fa fa-star color-yellow-dark" />
                                <i className="fa fa-star color-yellow-dark" />
                                <i className="fa fa-star color-yellow-dark" />
                                <i className="fa fa-star color-yellow-dark" />
                                <i className="fa fa-star-half-alt color-yellow-dark" />
                                </span>
                                <span className="fa fa-share-alt pl-2" />
                                <span className="fa fa-heart pl-2" />
                            </div>                                      
                            </div>
                        </div>
                        <div className="d-flex mb-2">
                            <div className="col-7 pl-1">
                            <video width="100%" poster="assets/images/ark/digital.png" controls loop muted>
                                <source src="assets/images/ark/saree.mp4" type="video/mp4" />
                                <source src="mov_bbb.ogg" type="video/ogg" />
                                Your browser does not support HTML video.
                            </video>
                            </div>
                            <div className="col-5 p-0">
                            <a href='product_details'>
                                <h5 className="font-500 font-14">Wedding Design name</h5>
                                <p className="mt-n2 mb-1 font-11">WED00000001</p>
                            </a>
                            <button className="border-info btn btn-border btn-xxs text-info font-600 mb-1 rounded-xl text-sentance">Modren</button>
                            <button className="border-info btn btn-border btn-xxs text-info font-600 mb-1 rounded-xl text-sentance">Hindus</button>
                            <del className="font-11" style={{color: '#f37795'}}>$50.00</del>&nbsp;<span className="font-500 font-14">$37.88</span>&nbsp;<span className="font-11" style={{color: 'aquamarine !important'}}>-60%OFF</span>
                            <div>
                                <span>
                                <i className="fa fa-star color-yellow-dark" />
                                <i className="fa fa-star color-yellow-dark" />
                                <i className="fa fa-star color-yellow-dark" />
                                <i className="fa fa-star color-yellow-dark" />
                                <i className="fa fa-star-half-alt color-yellow-dark" />
                                </span>
                                <span className="fa fa-share-alt pl-2" />
                                <span className="fa fa-heart pl-2" />
                            </div>                                      
                            </div>
                        </div>
                        <div className="d-flex mb-2">
                            <div className="col-7 pl-1">
                            <video width="100%" poster="assets/images/ark/digital.png" controls loop muted>
                                <source src="assets/images/ark/saree.mp4" type="video/mp4" />
                                <source src="mov_bbb.ogg" type="video/ogg" />
                                Your browser does not support HTML video.
                            </video>
                            </div>
                            <div className="col-5 p-0">
                            <a href='product_details'>
                                <h5 className="font-500 font-14">Wedding Design name</h5>
                                <p className="mt-n2 mb-1 font-11">WED00000001</p>
                            </a>
                            <button className="border-info btn btn-border btn-xxs text-info font-600 mb-1 rounded-xl text-sentance">Modren</button>
                            <button className="border-info btn btn-border btn-xxs text-info font-600 mb-1 rounded-xl text-sentance">Hindus</button>
                            <del className="font-11" style={{color: '#f37795'}}>$50.00</del>&nbsp;<span className="font-500 font-14">$37.88</span>&nbsp;<span className="font-11" style={{color: 'aquamarine !important'}}>-60%OFF</span>
                            <div>
                                <span>
                                <i className="fa fa-star color-yellow-dark" />
                                <i className="fa fa-star color-yellow-dark" />
                                <i className="fa fa-star color-yellow-dark" />
                                <i className="fa fa-star color-yellow-dark" />
                                <i className="fa fa-star-half-alt color-yellow-dark" />
                                </span>
                                <span className="fa fa-share-alt pl-2" />
                                <span className="fa fa-heart pl-2" />
                            </div>                                      
                            </div>
                        </div>
                        <div className="d-flex mb-2">
                            <div className="col-7 pl-1">
                            <video width="100%" poster="assets/images/ark/digital.png" controls loop muted>
                                <source src="assets/images/ark/saree.mp4" type="video/mp4" />
                                <source src="mov_bbb.ogg" type="video/ogg" />
                                Your browser does not support HTML video.
                            </video>
                            </div>
                            <div className="col-5 p-0">
                            <a href='product_details'>
                                <h5 className="font-500 font-14">Wedding Design name</h5>
                                <p className="mt-n2 mb-1 font-11">WED00000001</p>
                            </a>
                            <button className="border-info btn btn-border btn-xxs text-info font-600 mb-1 rounded-xl text-sentance">Modren</button>
                            <button className="border-info btn btn-border btn-xxs text-info font-600 mb-1 rounded-xl text-sentance">Hindus</button>
                            <del className="font-11" style={{color: '#f37795'}}>$50.00</del>&nbsp;<span className="font-500 font-14">$37.88</span>&nbsp;<span className="font-11" style={{color: 'aquamarine !important'}}>-60%OFF</span>
                            <div>
                                <span>
                                <i className="fa fa-star color-yellow-dark" />
                                <i className="fa fa-star color-yellow-dark" />
                                <i className="fa fa-star color-yellow-dark" />
                                <i className="fa fa-star color-yellow-dark" />
                                <i className="fa fa-star-half-alt color-yellow-dark" />
                                </span>
                                <span className="fa fa-share-alt pl-2" />
                                <span className="fa fa-heart pl-2" />
                            </div>                                      
                            </div>
                        </div>
                    </div>
                </div>
                {/* Product Details List End */}

                {/* Price Details start */}
                <div className="content mb-0 mt-1">
                    <h5>PRICE DETAILS [5 ITEMS]</h5>
                    <div className="d-flex justify-content-between">
                        <p className="mb-1"><span>Total</span> <span className="ml-5">MRP</span></p>
                        <p>$200.00</p>
                    </div>
                    <div className="d-flex justify-content-between">
                        <p className="mb-2"><span>Discount</span> &nbsp; <span className="ml-3">MRP</span></p>
                        <p>$50.00</p>
                    </div>
                    <div className="d-flex justify-content-between">
                        <p className="mb-2"><span className="font-600 font-15">Coupon Apply [ARK10]</span></p>
                        <p>$10.00</p>
                    </div>
                    <div className="divider divider-margins mb-2 ml-0 mr-0" />
                    <div className="d-flex justify-content-between">
                        <p className="mb-2"><span>TOTAL AMOUNT</span></p>
                        <p>$140.00</p>
                    </div>
                    <div className="divider divider-margins mb-2 ml-0 mr-0" />
                    <a href="confirm_order" className="btn btn-center-l btn-xs shadow-xl bg-highlight font-700 font-16 text-uppercase mb-2 button-width-100">Place ORDER</a>
                    <div className="divider divider-margins mb-0 ml-0 mr-0" />
                </div>
                {/* Price Details end */}
            </div>

        </div>
    </>
  )
}
