import React from 'react'
import Footer from '../../landingpages/footer/footer'
import OrderSuccessContent from './order_success_content'

export default function OrderSuccess() {
  return (
    <>
        <OrderSuccessContent />
        <Footer />
    </>
  )
}
