import React from 'react'

export default function ResetPass() {
  return (
    <>
        <div id='page'>
            {/*  */}
            <div className="page-content pb-0 mb-0">
                <div className="card" data-card-height="cover">
                    <div className="card-center px-4" style={{top: '48%'}}>
                        <h2 className="text-center font-500 mb-4">Reset Password</h2>
                        <img src="assets/images/ark/password.png" alt="" width="180px" className="d-block m-auto" />
                        <div className="input-style input-style-2 input-required mt-3">
                        <input className="form-control border border-secondary" type="email" placeholder="Old Password" />
                        </div>         
                        <div className="input-style input-style-2 input-required mt-3">
                        <input className="form-control border border-secondary" type="email" placeholder="New Password" />
                        </div>         
                        <div className="input-style input-style-2 input-required mt-3">
                        <input className="form-control border border-secondary" type="email" placeholder="Confirm Password" />
                        </div>         
                        <a href="#" className="btn btn-full btn-xs bg-dark rounded-s mt-3 font-400 font-13 shadow-xl">Reset</a>
                    </div>
                </div>
            </div>
            {/*  */}
        </div>
    </>
  )
}
