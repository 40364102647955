import React from 'react'
import Footer from '../../landingpages/footer/footer'
import OrderFailedContent from './order_failed_content'

export default function OrderFailed() {
  return (
    <>
        <OrderFailedContent />
        <Footer />
    </>
  )
}
