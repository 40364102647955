import React from 'react'

export default function EmptyCartContent() {
  return (
    <>
        <div id='page'>
            {/* Top fixed header */}
            <div className="page-subtitle mb-2 mt-2">
                <a href="#" className="page-subtitle-icon" data-back-button><i className="fa fa-arrow-left color-theme" /></a>
                <h1 className="font-18 font-500">Cart</h1>
                <a href="#" className="page-subtitle-icon" data-menu="menu-main"><i className="fa fa-bars color-theme" /></a>
            </div> 
            {/* Top fixed header */}

            {/* empty cart start */}
            <div className="page-content pb-0 mb-0">
                <div className="card theme-dark" data-card-height="500">
                    <div className="card-center px-4" style={{top: '50%'}}>
                        <img src="assets/images/ark/trolley.png" alt="" width="160px" className="d-block m-auto" />
                        <h1 className="font-500 text-center mt-4">Your cart is empty!</h1>      
                        <h3 className="text-center font-400 font-14 text-secondary mt-2">Looks like you haven't made your order yet.</h3> 
                        <div className="text-center">     
                            <a href="#" className="btn btn-full btn-xs bg-white text-dark rounded-s mt-2 font-400  shadow-xl">Shop Now</a>
                        </div>
                    </div>
                </div>
            </div>
            {/* empty cart end */}
        </div>
    </>
  )
}
