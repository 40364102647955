import React from 'react';
import { Link } from 'react-router-dom';


export default function HomeContent() {
  return (
    <>
        <div id='page'>
            
            {/* header */}
            <div className="header header-fixed header-logo-app">
                <a href="#" data-back-button className="header-icon header-icon-1"><img className="main-header-icon" src="assets/images/ark/logo-bg.png" alt="" width="70px" /></a>
                <a href="#" ata-toggle-theme className="header-icon header-icon-2"><img className="main-header-icon-right mt-1" src="assets/images/ark/shopping-bag.png" alt="" width="35px" /></a>
                <a href="#" data-toggle-theme className="header-icon header-icon-3"><img className="main-header-icon-right mt-1" src="assets/images/ark/bell_white.png" alt="" width="30px" /></a>
                <a href="#" data-toggle-theme className="header-icon header-icon-4"><img className="main-header-icon-right mt-1" src="assets/images/ark/magnifying-glass.png" alt="" width="32px" /></a>
            </div>    
            {/* header */}
            

            {/* Page content starts */}
            <div className="page-content header-clear">
                <div className="content">
                    <div className="single-slider owl-no-controls owl-carousel owl-no-dots">
                        <div className='item'>
                            <video className="custom-video-width" poster="assets/images/ark/digital.png" controls loop muted>
                            <source src="assets/images/ark/saree.mp4" type="video/mp4" />
                            <source src="mov_bbb.ogg" type="video/ogg" />
                            Your browser does not support HTML video.
                            </video>
                        </div>
                        <div className='item'>
                            <video className="custom-video-width" poster="assets/images/ark/digital.png" controls loop muted>
                            <source src="assets/images/ark/saree.mp4" type="video/mp4" />
                            <source src="mov_bbb.ogg" type="video/ogg" />
                            Your browser does not support HTML video.
                            </video>
                        </div>
                        <div className='item'>
                            <video className="custom-video-width" poster="assets/images/ark/digital.png" controls loop muted>
                            <source src="assets/images/ark/saree.mp4" type="video/mp4" />
                            <source src="mov_bbb.ogg" type="video/ogg" />
                            Your browser does not support HTML video.
                            </video>
                        </div>
                    </div>   
                </div>
                <h3 className="ml-3 mt-4 mb-1">CATEGORIES</h3>
                <div className="divider bg-blue-dark divider-margins ml-3 mb-1" style={{width: '35%'}} />
                <div className="divider bg-blue-dark divider-margins ml-4" style={{width: '37%'}} />
                <div className="row mr-0 ml-0 mb-0 pt-2">
                <div className="col-6">
                    <Link to="sub_category">
                    <img src="assets/images/ark/digital.png" className="img-fluid mb-2" style={{height: '60%', width: '100%'}} />
                    <p className="text-uppercase font-600 text-white mb-0">Digital Invitation</p>
                    </Link>
                </div>
                <div className="col-6">
                    <Link to="sub_category">
                    <img src="assets/images/ark/motion.png" className="img-fluid mb-2" style={{height: '60%', width: '100%'}} />
                    <p className="text-uppercase font-600 text-white mb-0">Motion Promos</p>
                    </Link>
                </div>
                </div>    
                <div className="row mr-0 ml-0 mb-0">
                <div className="col-6">
                    <Link to="sub_category">
                    <img src="assets/images/ark/stories.jpg" className="img-fluid mb-2" style={{height: '60%', width: '100%'}} />
                    <p className="text-uppercase font-600 text-white mb-0">Digital Stories</p>
                    </Link>
                </div>
                <div className="col-6">
                    <Link to="sub_category">
                    <img src="assets/images/ark/intro.jpg" className="img-fluid mb-2" style={{height: '60%', width: '100%'}} />
                    <p className="text-uppercase font-600 text-white mb-0">Motion Intros</p>
                    </Link>
                </div>        
                </div>    
                <div className="row mr-0 ml-0 mb-0">
                <div className="col-6">
                    <Link to="sub_category">
                    <img src="assets/images/ark/service.jpg" className="img-fluid mb-2" style={{height: '60%', width: '100%'}} />
                    <p className="text-uppercase font-600 text-white mb-0">Services Promos</p>
                    </Link>
                </div>
                <div className="col-6">
                    <Link to="sub_category">
                    <img src="assets/images/ark/festival.jpg" className="img-fluid mb-2" style={{height: '60%', width: '100%'}} />
                    <p className="text-uppercase font-600 text-white mb-0">Festival Events</p>
                    </Link>
                </div>        
                </div>    
                {/* Offers box starts */}
                <div className="content border border-rounded border-secondary rounded pt-2 pl-3 pr-3 pb-2 ofr-box" style={{background: 'linear-gradient(90deg, hsla(233, 100%, 90%, 1) 0%, hsla(0, 0%, 89%, 1) 100%)'}}>
                <a href="offers.html">
                    <h5><img src="assets/images/ark/logo-bg.png" style={{width: '40px'}} alt="" />
                    <span className="m-1 blink-1">DISCOUNT OFFERS</span>
                    <span className="float-right font-weight-normal border border-dark rounded pr-2 pl-2 mt-1 text-white" style={{fontSize: '13px', backgroundColor: '#000e82f0'}}>
                        View All
                    </span>
                    </h5>
                </a>
                <span>
                    Get best deals with great discounts.
                </span>
                </div>
                {/* Offers box ends */}
                {/* Offers slider starts */}
                <div>
                <h3 className="float-right mr-3 mb-1">LATEST DESIGNS</h3><br />
                <div className="divider bg-blue-dark divider-margins mb-1" style={{marginLeft: '60%'}} />
                <div className="divider bg-blue-dark divider-margins mb-3" style={{marginLeft: '55%', marginRight: '8%'}} />
                </div>
                <div className="double-slider owl-carousel owl-no-dots pt-2 ofr-sldr" style={{background: 'linear-gradient(90deg, hsla(283, 83%, 86%, 1) 0%, hsla(105, 11%, 85%, 1) 100%)'}}>
                <div className="item">
                    <div data-card-height={185} style={{backgroundImage: 'url("assets/images/ark/digital.jpg")'}} className="card rounded-m">
                    <div className="card-bottom text-center mb-1">
                        <h2 className="color-white text-uppercase font-900" />
                    </div>
                    </div>
                </div>
                <div className="item">
                    <div data-card-height={185} style={{backgroundImage: 'url("assets/images/ark/latest1.jpg")'}} className="card rounded-m">
                    <div className="card-bottom text-center mb-1">
                        <h2 className="color-white text-uppercase font-900" />
                    </div>
                    </div>
                </div>
                <div className="item">
                    <div data-card-height={185} style={{backgroundImage: 'url("assets/images/ark/wedding.jpg")'}} className="card rounded-m">
                    <div className="card-bottom text-center mb-1">
                        <h2 className="color-white text-uppercase font-900" />
                    </div>
                    </div>
                </div>
                <div className="item">
                    <div data-card-height={185} style={{backgroundImage: 'url("assets/images/ark/event.jpg")'}} className="card rounded-m">
                    <div className="card-bottom text-center mb-1">
                        <h2 className="color-white text-uppercase font-900" />
                    </div>
                    </div>
                </div>
                </div>  
                {/* Offers slider ends */}
            </div> 
            {/* Page content ends */}

        </div>
    </>
  )
}
