import React from 'react'
import Footer from '../../landingpages/footer/footer'
import CartContent from './cart_content'

export default function Cart() {
  return (
    <>
        <CartContent />
        <Footer />
    </>
  )
}
