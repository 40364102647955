import React from 'react'

export default function OrderFailedContent() {
  return (
    <>
        <div id='page'>
            {/* Top fixed header */}
            <div className="page-subtitle mb-2 mt-2 border-bottom">
                <a href="#" className="page-subtitle-icon" data-back-button><i className="fa fa-arrow-left color-theme" /></a>
                <h1 className="font-18 font-500"></h1>
                <a href="#" className="page-subtitle-icon" data-menu="menu-main"><i className="fa fa-bars color-theme" /></a>
            </div> 
            {/* Top fixed header */}
            
            {/* Page content stat */}
            <div className="page-content pb-0 mb-0">
                <div className="card theme-dark" data-card-height="500">
                    <div className="card-center px-4" style={{ top: '55%' }}>
                        <img src="assets/images/ark/remove.png" alt="Order failed" width="230px" className="d-block m-auto" />
                        <h1 className="font-500 text-center mt-4">Sorry! Your order has failed!</h1>      
                        <h3 className="text-center font-400 font-14 text-secondary mt-2">Something went wrong. Please try again to continue your order.</h3> 
                        <div className="text-center">     
                            <a href="confirm_order" className="btn btn-full btn-xs bg-white text-dark rounded-s mt-2 font-400  shadow-xl">Try again</a>
                        </div>
                        <a href="/" className="btn btn-full btn-xs bg-dark rounded-s mt-2 font-400 text-white">Go to Home page</a>
                    </div>
                </div>
            </div>
            {/* Page content end */}
        </div>
    </>
  )
}
