import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from "../landingpages/homepage/home";
import Cart from '../productpages/cart/cart';
import ProductDetails from '../productpages/product_details/product_details';
import ProductList from '../productpages/product_list/product_list';
import Login from '../login/login'
import Registration from '../registration/registration';
import SubCategory from '../sub_category/sub_category';
import ConfirmOrder from '../productpages/confirm_order/confirm_order';
import EmptyCart from '../productpages/cart/empty_cart';
import OrderFailed from '../productpages/confirm_order/order_failed';
import OrderSuccess from '../productpages/confirm_order/order_success';
import PhoneVerification from '../login/phone_verification';
import ResetPass from '../login/reset_pass';
import AccountCreated from '../registration/account_created';
import MobileLogin from '../login/mobile_login';
import Review from '../productpages/product_reviews/review';

export default function ManagingRoutes() {
  return (
    <>
        <Router>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='login' element={<Login />} />
            <Route path='mobile_login' element={<MobileLogin />} />
            <Route path='registration' element={<Registration />} />
            <Route path='account_created' element={<AccountCreated />} />
            <Route path='phone_verification' element={<PhoneVerification />} />
            <Route path='reset_pass' element={<ResetPass />} />
            <Route path='sub_category' element={<SubCategory />} />
            <Route path='products' element={<ProductList />} />
            <Route path='product_details' element={<ProductDetails />} />
            <Route path='reviews' element={<Review />} />
            <Route path='cart' element={<Cart />} />
            <Route path='empty_cart' element={<EmptyCart />} />
            <Route path='confirm_order' element={<ConfirmOrder />} />
            <Route path='order_failed' element={<OrderFailed />} />
            <Route path='order_success' element={<OrderSuccess />} />
          </Routes>
        </Router>
    </>
  )
}
