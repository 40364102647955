import React from 'react'

export default function PhoneVerification() {
  return (
    <>
        <div id='page'>
            <div className="page-subtitle mt-1 mb-2">
                <a href="#" className="page-subtitle-icon mt-3" data-back-button><i className="fa fa-arrow-left color-theme" /></a>
            </div>
            
            <div className="divider divider-margins mb-0" />

            <div className="content pt-5 pb-5 mt-5">
                <h5 className="font-24 font-500 text-center mb-4 custom-forget">Verify your phone number</h5>
                <p className="text-center font-600 font-16">Enter your OTP</p>
                <div className="text-center mb-5">
                    <input type="number" className="custom-otp-input mr-1" name id />
                    <input type="number" className="custom-otp-input mr-1" name id />
                    <input type="number" className="custom-otp-input mr-1" name id />
                    <input type="number" className="custom-otp-input mr-1" name id />
                </div>
                <p className="text-center font-14">Didn't receive OTP? <b className="color-white font-16">Resend</b> </p>
                <a href="/" className="btn btn-center-l btn-xs shadow-xl bg-secondary font-700 font-16 text-uppercase mb-2 button-width-100">verify otp</a>
            </div>
        </div>
    </>
  )
}
